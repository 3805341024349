export default {
  data: {
    navigation: {
      __typename: 'Navigation',
      sys: {
        __typename: 'Sys',
        id: '1fEnyeyxvMcSzg9Vi8k6Q6'
      },
      headerNavigation: {
        Menu: [
          {
            link: '/how-it-works',
            title: 'How It Works'
          },
          {
            link: '/for-law-firms',
            title: 'Why Proof',
            subItems: [
              {
                link: '/for-law-firms',
                title: 'For Law Firms'
              },
              {
                link: '/for-collections-agencies',
                title: 'For Collections'
              },
              {
                link: '/for-individuals',
                title: 'For Individuals'
              },
              {
                link: '/for-government',
                title: 'For Government'
              },
              {
                link: '/for-process-serving-companies',
                title: 'For Companies'
              },
              {
                link: '/for-servers',
                title: 'For Servers'
              },
              {
                link: '/for-law-enforcement',
                title: 'For Law Enforcement'
              }
            ]
          },
          {
            link: '/pricing',
            title: 'Pricing'
          },
          {
            link: '/learn',
            title: 'Resources',
            subItems: [
              {
                link: '/learn',
                title: 'Blog'
              },
              {
                link: '/events',
                title: 'Events'
              },
              {
                link: '/about#press',
                title: 'Press'
              },
              {
                link: 'https://developer.proofserve.com/#introduction',
                title: 'API Docs'
              },
              {
                link: '/contact',
                title: 'Contact Us'
              },
              {
                link: '/service-areas',
                title: 'Service Areas'
              },
              {
                link: 'https://status.proofserve.com/',
                title: 'Status'
              },
              {
                link: '/success-stories',
                title: 'Success Stories'
              }
            ]
          },
          {
            link: '/integrations/clio',
            title: 'Apps'
          },
          {
            link: '/calculator/firm',
            title: 'Value Calculator',
            subItems: [
              {
                link: '/calculator/firm',
                title: 'Firm'
              },
              {
                link: '/calculator/property-management',
                title: 'Property Management'
              },
              {
                link: '/calculator/collection',
                title: 'Collections'
              }
            ],
            alignment: 'vertical'
          }
        ]
      },
      footerNavigation: {
        Footer: [
          {
            name: 'How it works',
            items: [
              {
                url: '/how-it-works',
                name: 'Overview'
              },
              {
                url: '/pricing',
                name: 'Pricing'
              },
              {
                url: '/service-areas',
                name: 'Service Areas'
              },
              {
                url: '/become-a-process-server',
                name: 'Become a Server'
              }
            ]
          },
          {
            name: 'Why Proof',
            items: [
              {
                url: '/for-law-firms',
                name: 'For Law Firms'
              },
              {
                url: '/for-collections-agencies',
                name: 'For Collections Agencies'
              },
              {
                url: '/for-individuals',
                name: 'For Individuals'
              },
              {
                url: '/for-government',
                name: 'For Government'
              },
              {
                url: '/for-process-serving-companies',
                name: 'For Process Serving Companies'
              },
              {
                url: '/for-servers',
                name: 'For Servers'
              },
              {
                url: '/for-partners',
                name: 'For Partners'
              }
            ]
          },
          {
            name: 'Resources',
            items: [
              {
                url: '/learn',
                name: 'Blog'
              },
              {
                url: '/about#press',
                name: 'Press'
              },
              {
                url: '/events',
                name: 'Events'
              },
              {
                url: 'https://status.proofserve.com/',
                name: 'Status'
              },
              {
                url: 'https://developer.proofserve.com/#introduction',
                name: 'API Docs'
              },
            ]
          },
          {
            name: 'Company',
            items: [
              {
                url: '/about',
                name: 'About'
              },
              {
                url: '/careers',
                name: 'Careers'
              },
              {
                url: '/contact',
                name: 'Contact'
              },
            ]
          },
          {
            name: 'Social',
            items: [
              {
                url: 'https://www.facebook.com/Proofserve/',
                name: 'Facebook'
              },
              {
                url: 'https://twitter.com/legalproof',
                name: 'Twitter'
              },
              {
                url: 'https://www.linkedin.com/company/proofapp/',
                name: 'LinkedIn'
              }
            ]
          }
        ]
      },
      logoLottieFile: {
        __typename: 'Asset',
        url: 'https://assets.ctfassets.net/lg0s2zdhlsw6/6vN0S6ziDBhomVHxUCIJX3/5d35a931e4b681714d4440d46019a831/proof-masthead-logo.json'
      },
      logoWhiteLottieFile: {
        __typename: 'Asset',
        url: 'https://assets.ctfassets.net/lg0s2zdhlsw6/12XTKb74stjTF2QSgIROEC/06f625bdd22b95b4f646c97e400aa378/lf30_editor_4die2cdj.json'
      }
    }
  },
  loading: false,
  networkStatus: 7
};

import Link from 'next/link';
import React from 'react';
import LandingFooter from './Landing/LandingFooter';
import navData from '~/data/contentful/navData';

function Footer({ isLandingPage = false, footerType = 'default', customHeaderButton = [] }) {
  const footerNav = navData.data.navigation.footerNavigation.Footer;

  if (footerType === 'hidden') return null;

  if (isLandingPage || footerType === 'landing') {
    return <LandingFooter navData={navData} customHeaderButton={customHeaderButton} />;
  }

  return (
    <footer className="pt-20 pb-24 md:pt-24 md:pb-20">
      <div className="container">
        <div className="justify-between md:flex">
          <div className="text-center lg:w-4/12 md:text-left">
            <div className="inline-block w-8 mb-8">
              <svg width="100%" height="100%" viewBox="0 0 222.54067 304.07594">
                <path d="M84.46169,304.07594H14.24264a13.88633,13.88633,0,0,1-9.737-4.13c-4.72-4.721-5.606-5.901-3.245-80.251,0-1.77,1.18-46.321,26.553-80.251a119.28033,119.28033,0,0,1-18.882-59.008c-1.18-29.504,8.851-56.647,28.324-76.415a13.44382,13.44382,0,0,1,18.882-.295,14.1005,14.1005,0,0,1,0,19.177c-25.963,26.554-25.078,67.565-8.556,97.364,21.538-15.047,55.172-23.899,79.66095-14.457,16.22705,6.195,26.258,20.652,25.963,37.175-.295,16.522-11.211,30.979-27.733,36.29-25.374,8.261-58.123.295-79.956-18.883-16.818,26.554-17.703,59.598-17.703,59.893-.59,21.243-1.18,43.961-1.18,57.238h44.551v-49.567a13.22194,13.22194,0,0,1,13.277-13.277h37.47c36.88,0,74.35-25.373,74.35-73.465,0-31.569-18.292-68.154-69.334-68.154h-42.191a13.27651,13.27651,0,1,1,0-26.553h41.896c29.799,0,54.877,10.326,72.284,29.504,15.343,17.112,23.604,40.125,23.604,65.203a102.59243,102.59243,0,0,1-26.259,69.04c-18.587,20.357-44.551,31.274-74.645,31.274h-24.193v49.567A12.72391,12.72391,0,0,1,84.46169,304.07594Zm-20.358-162.862c14.752,12.392,37.175,18.293,53.402,12.982,8.557-2.95,9.442-9.736,9.442-11.801,0-2.656-.59-8.852-8.851-11.802C102.75369,124.69191,79.15066,131.18291,64.10366,141.21391Z" fill="black"/>
                <path d="M201.34567,303.41994a20.93845,20.93845,0,0,1-21.194-21.194,21.27508,21.27508,0,0,1,10.44-18.376,21.21492,21.21492,0,0,1,10.754-2.819,21.0758,21.0758,0,0,1,21.194,21.195,21.19863,21.19863,0,0,1-2.819,10.753,21.27308,21.27308,0,0,1-18.375,10.441Zm0-3.863a16.99632,16.99632,0,0,0,8.666-2.297,17.71457,17.71457,0,0,0,6.264-6.369,16.8379,16.8379,0,0,0,0-17.331,16.9,16.9,0,0,0-6.264-6.264,16.83395,16.83395,0,0,0-17.332,0,17.71218,17.71218,0,0,0-6.368,6.264,17.49391,17.49391,0,0,0,0,17.331,18.59052,18.59052,0,0,0,6.368,6.369A16.99636,16.99636,0,0,0,201.34567,299.5569Zm-9.083-29.651h11.589a8.11389,8.11389,0,0,1,5.638,1.879q2.1915,1.77447,2.192,5.534a9.19636,9.19636,0,0,1-.417,2.714,7.17918,7.17918,0,0,1-1.044,2.193,5.47967,5.47967,0,0,1-1.149.835,2.40333,2.40333,0,0,1-1.357.418l-.105.417a6.22093,6.22093,0,0,1,.94,1.149,7.53338,7.53338,0,0,1,.626,1.461l3.237,8.04h-6.16l-3.55-9.501h-4.698v9.501h-5.742Zm10.754,10.127q2.922,0,2.923-2.506a2.08827,2.08827,0,0,0-.835-1.774,2.82446,2.82446,0,0,0-2.08795-.731h-5.012v5.011Z" fill="black"/>
              </svg>
            </div>
            <h3 className="mb-8 text-3xl md:max-w-xs u-h3">The smarter way to serve legal documents</h3>
            <Link href="/how-it-works">
              <a className="btn">How it works</a>
            </Link>
          </div>
          <div className="pt-12 mt-12 border-t border-dashed md:w-7/12 md:pl-12 lg:pl-24 md:border-l md:mt-0 md:pt-0 md:border-t-0" style={{ borderColor: 'rgba(0, 0, 0, 0.1)' }}>
            <div className="grid grid-cols-2 gap-y-8 md:gap-y-16 md:grid-cols-3">
              {footerNav.map(({ name, items }, i) => (
                <div key={`col${name + i}`}>
                  <h4 className="text-sm u-h3">{name}</h4>
                  <div className="mt-5 space-y-2">
                    {items.map(item => (
                      <ul key={name + item.url} className="list-none">
                        <li>
                          <Link href={item.url}>
                            <a target={item.url[0] !== '/' ? '_blank' : ''} className="text-sm transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100">
                              {item.name}
                            </a>
                          </Link>
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-16 text-center md:mt-20 md:text-left">
          <div className="lg:w-7/12 md:pl-24 md:ml-auto md:flex">
            <p className="text-xs opacity-50">© {new Date().getFullYear()} Proof Technology, Inc.</p>
            <div className="flex justify-center mt-4 space-x-4 md:justify-start md:ml-24 md:mt-0">
              <Link href="/legal/privacy-policy">
                <a className="text-xs underline transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100">Terms & Conditions</a>
              </Link>
              <Link href="/legal/privacy-policy">
                <a className="text-xs underline transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100">Privacy Policy</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
